@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,500i,600,700");
@font-face {
  font-family: "MuliRegular";
  src: url("../fonts/MuliRegular.woff2") format("woff2"),
    url("../fonts/MuliRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MuliBold";
  src: url("../fonts/MuliBold.woff2") format("woff2"),
    url("../fonts/MuliBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
:root {
  --main-theme-color: #1654c3;
  --main-primary-color: #000000;
}
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: 0 !important;
}
html,
body {
  width: 100%;
  min-height: 100%;
  font-size: 10px;
  line-height: 1.42857143;
}
body {
  font-family: "Mulish", sans-serif;
  overflow-x: hidden;
  margin: 0;
  background: url(../images/foloosi_banner.png) no-repeat bottom;
  min-height: 100vh;
  color: var(--main-primary-color);
}
img {
  max-width: 100%;
}
a {
  cursor: pointer;
}

.fls_payment_body {
  height: 100%;
  padding: 25px 0;
  margin: 0 auto;
  max-width: 900px;
  flex-direction: column;
  min-height: 100vh;
  transition: all 2s ease 0s;
  -webkit-transition: all 2s ease 0s;
  -moz-transition: all 2s ease 0s;
  -ms-transition: all 2s ease 0s;
}
.fls_payment_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.fls-card-right {
  width: 40%;
  background-color: #fff;
  border: 2px solid #fafafa;
  box-shadow: 0 -1.715px 9.4325px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(10px);
  border-radius: 0;
  margin-left: -50px;
}

.fls_customer_title {
  display: flex;
  background: #fefefe;
  padding: 15px 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--main-theme-color);
  text-transform: uppercase;
  cursor: default;
}

.fls-filling-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
}
.fls-card-frame {
  display: flex;
  width: 100%;
  margin: 10px 0;
}
.fls-card-frame input {
  display: flex;
  width: 100%;
  border: none;
  background: #f9f9f9;
  border-radius: 6px;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  transition: all 0.3s ease-in-out 0s;
}

.fls-card-frame input:focus {
  letter-spacing: 1px; /* Smoothly increases spacing */
}

.fls-contant-name {
  display: flex;
  width: 100px;
  background: #f9f9f9;
}
.fls-select-country {
  display: flex;
  width: 46px;
  height: 100%;
}

.fls-flag {
  display: flex;
  width: 46px;
  padding: 0 0 0 8px;
  height: 100%;
  z-index: 1;
}
.fls-flag .iti-flag {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.fls-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.fls-contant-name::after {
  background-color: #cfcfcf;
  height: 15px;
  width: 2px;
  content: "";
  position: absolute;
  top: 13px;
  right: -4px;
}
.fls-code {
  line-height: 38px;
  padding: 0 2px;
  width: 52px;
  text-align: center;
  display: flex;
  font-size: 16px;
}
.fls-contant-number {
  display: flex;
  width: calc(100% - 100px);
  background: #f9f9f9;
}

.fls-btn-btn {
  display: flex;
  width: 100%;
  padding: 0 20px;
  margin: 15px 0 15px;
}
.fls-proceed-to-pay {
  text-align: center;
  width: 100%;
  background-color: var(--main-theme-color);
  color: #fff;
  border: 1px solid var(--main-theme-color);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  transition: all 2s ease-in-out;
}
.fls-btn-loader {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: repeating-conic-gradient(
    var(--main-theme-color) 0 90deg,
    #fff 0 180deg
  );
  animation: l21 1s infinite linear;
  margin: 0 auto;
}
@keyframes l21 {
  100% {
    transform: rotate(0.5turn);
  }
}

.fls-country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #ccc;
  max-height: 220px;
  overflow-y: scroll;
  top: 40px;
  min-width: 335px;
}
.fls-country-list li {
  cursor: pointer;
}
.fls-country {
  padding: 5px 10px;
}
.fls-flag-box {
  display: inline-block;
  vertical-align: middle;
}
.fls-country-name {
  margin: 0 7px;
}

.error-msg {
  display: flex;
  width: 100%;
  font-size: 11px;
  color: #ff5555;
}
.error-line {
  border: 1px solid #ff5555 !important;
  border-radius: 3px;
}

.fls-card-support {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fafafa;
  box-shadow: 0px -9.4325px 12.8625px rgba(0, 0, 0, 0.05);
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  padding: 20px 10px;
}

.fls-support-title {
  display: flex;
  width: 100%;
  color: #144378;
  font-size: 11px;
  font-weight: 800;
  margin-bottom: 10px;
}

.fls-support-list {
  display: flex;
  width: 100%;
  color: #757575;
  font-size: 12px;
  font-weight: 400;
}
.fls-card-img {
  display: flex;
  justify-content: end;
  background: #f9f9f9;
  box-shadow: 0px -9.4325px 12.8625px rgba(0, 0, 0, 0.05);
  padding: 15px;
  width: 100%;
}

.fls-card-img img {
  margin-left: 10px;
  height: 15px;
}

.fls-card-left {
  width: 60%;
  background: #ffffff;
  box-shadow: 0px -2px 11px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}
.fls-merchant-title {
  display: flex;
  width: 100%;
  background: var(--main-theme-color);
  padding: 15px 25px;
  border-radius: 16px 16px 0 0;
  align-items: center;
}
.fls-merchant-logo {
  display: flex;
  padding: 3px 6px;
  background: #fff;
  border-radius: 11px;
}
.fls-logo {
  height: 37px;
  width: 32px;
}

.fls-merchant-name {
  display: flex;
  font-size: 20px;
  font-weight: 800;
  color: #ffffff;
  margin-left: 8px;
}

.fls-merchnat-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  padding: 20px 65px 20px 12px;
}
.fls-sub-title {
  display: flex;
  width: 100%;
  color: #686868;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}
.fls-sub-details {
  display: flex;
  width: 100%;
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
}
.fls-merchant-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fls-Pay-amount {
  display: flex;
  width: 100%;
  color: var(--main-theme-color);
  font-size: 20px;
  font-weight: 600;
}

.fls-merchnat-bottom {
  display: flex;
  width: 100%;
  align-items: center;
  background: #f5f5f5;
  padding: 15px 25px;
  border-radius: 0 0 16px 16px;
}
.fls-merchnat-bottom img {
  object-fit: contain;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-text {
  border-radius: 4px;
}

.skeleton-circle {
  border-radius: 50%;
}

.skeleton-rect {
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.fls-customers-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: #fefefe;
  padding: 15px 10px;
}
.fls-customer-name {
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 5px;
}
.fls-customer-contact {
  display: flex;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: var(--main-primary-color);
}
.fls-card-label {
  display: flex;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  color: #606060;
  margin-bottom: 5px;
}
.fls-card-number {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 5px 0 0;
  position: relative;
}
.fls-card-expiry {
  display: flex;
  width: 25%;
  flex-direction: column;
  padding: 0 2.5px;
}

.fls-card-cvv {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 0 0 0 5px;
}

.fls-cardtype-img {
  position: absolute;
  right: 20px;
  top: 0px;
  width: 36px;
  height: 18px;
  background-size: 100% 100%;
}
.frame-open {
  display: block;
}
.frame-close {
  display: none;
}
#foloosiPluginApiiframe {
  border: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
#foloosiPluginApiiframe.frame-open {
  display: block;
}
#foloosiPluginApiiframe.frame-close {
  display: none;
}
#foloosiPluginApiiframe body {
  background: none;
}
#foloosiPluginApiiframe {
  min-height: 320px !important;
}

.fls-customer-address {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fls-checkbox {
  display: flex;
  width: 100%;
  margin: 10px 0;
}
.fls-checkbox input {
  display: none;
}
.fls-custom-label {
  position: relative;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  padding-left: 25px;
}
.fls-custom-label::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  content: "";
  border-radius: 3px;
}
.fls-custom-label::after {
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  content: "";
}
.fls-custom-input:checked + .fls-custom-label::before {
  background-color: var(--main-theme-color);
}
.fls-custom-input:checked + .fls-custom-label::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.fls-customer-addressinfo {
  display: flex;
  width: 100%;
}
.fls-address {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 5px 0 0;
  position: relative;
}
.fls-address-field {
  display: flex;
  width: 100%;
  background: #f9f9f9;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  height: 38px;
  line-height: 18px;
  transition: all 0.3s ease-in-out 0s;
}
.fls-address-field:focus{
  letter-spacing: 1px;
}
.fls-cleave-code {
  display: flex;
  width: 100%;
  background: #f9f9f9;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  height: 38px;
  line-height: 18px;
}

.fls-card-size {
  padding: 25px 20px;
}

.fls-payment_modes {
  display: flex;
  width: 100%;
  margin: 5px 0;
}

.fls-card-pay {
  text-align: center;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  transition: 0.2s all;
}
.fls-card-pay img {
  max-height: 65px;
}
.fls-or {
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #d2cfcf;
  padding: 15px 0;
  position: relative;
}

.fls-or::before {
  position: absolute;
  top: calc(50% + 1px);
  left: calc(50% - 80px);
  right: calc(50% + 22px);
  background: #d2cfcf;
  content: "";
  height: 1px;
}
.fls-or::after {
  position: absolute;
  top: calc(50% + 1px);
  right: calc(50% - 80px);
  left: calc(50% + 22px);
  background: #d2cfcf;
  content: "";
  height: 1px;
}

.fls-card-cancel {
  text-align: center;
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
}
.fls-cancel {
  text-align: center;
  font-size: 18px;
  margin: 0 0 12px;
  width: 100%;
  font-weight: 700;
}

.fls-cancel-img {
  width: 70px;
}

.fls-cancel-notes {
  text-align: center;
  width: 100%;
  font-size: 13px;
  margin: 10px 0;
}

.fls-retry {
  text-align: center;
  font-size: 13px;
  padding: 5px 10px;
  color: #ff5555;
  border: 1px solid #ff5555;
  border-radius: 5px;
  margin: 10px 0;
  background-color: #fff;
  width: 30%;
}
.fls-done {
  text-align: center;
  font-size: 13px;
  padding: 5px 10px;
  color: #008000;
  border: 1px solid #008000;
  border-radius: 5px;
  margin: 10px 0;
  background-color: #fff;
  width: 30%;
  display: inline-block;
}

.fls-expired {
  text-align: center;
  width: 100%;
  color: #f54443;
  font-size: 17px;
  font-weight: 600;
  margin: 5px 0 10px;
}
.fls-expiry-reason {
  text-align: center;
  width: 100%;
  font-size: 13px;
  color: #777;
  font-weight: 400;
  margin: 20px 0 15px;
  padding: 0 15px;
}
.fls-c2pscreen-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 10px;
  background-color: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
}
.clickToPayFrame{
  height: 280px;
}
.fls_pay_ontherway {
  position: absolute;
  position: absolute;
  bottom: 150px;
  right: 15px;
}
.fls_pay_resend {
  color: #000;
  font-size: 10px;
  cursor: pointer;
  text-decoration: underline;
}
.fls_pay_resend:hover {
  color: #ff0000;
  text-decoration: underline;
}
.fls-add_new_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}
src-otp-input,
src-otp-input *,
src-card-list,
src-card-list *,
src-otp-channel-selection,
src-otp-channel-selection * {
  font-family: "Poppins", "-apple-system,BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
src-otp-input,
src-otp-input *,
src-card-list,
src-card-list *,
src-otp-channel-selection,
src-otp-channel-selection * {
  font-family: "Poppins", "-apple-system,BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.fls-c2penable {
  display: flex;
  width: 100%;
  margin-top: 5px;
}
.fls-c2penable input {
  display: none;
}
.fls-custom_check_box {
  position: relative;
  cursor: pointer;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  color: #606060;
  padding-left: 25px;
}
.fls-custom_check_box::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  content: "";
  border-radius: 3px;
}
.fls-custom_check_box::after {
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  content: "";
}

.fls-checkmark_cust:checked + .fls-custom_check_box::before {
  background-color: var(--main-theme-color);
}
.fls-checkmark_cust:checked + .fls-custom_check_box::after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.learnmore_txt {
  position: absolute;
  font-size: 11px;
  bottom: 0;
  right: -3px;
}

.credit-card {
  margin: 0 0 20px 0;
  float: left;
  width: 100%;
}
.credit-card.selectable:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.credit-card-last4 {
  font-size: 14px;
  float: left;
  width: 100%;
  margin: 0px;
  line-height: 20px;
}
.credit-card-name {
  font-size: 12px;
  float: left;
  width: 100%;
  margin: 0 0 10px;
  line-height: 20px;
  color: #777;
}
.credit-card-expiry {
  font-size: 12px;
  float: right;
  margin: 0px;
  width: auto;
}
.credit-card-cvv {
  width: 90px;
  float: left;
  margin: 0px;
}
.credit-card-cvv-input {
  border: 0;
  width: 100%;
  padding: 4px 7px;
  background: #fff;
  border: 1px solid #e1e1e1;
}
.credit_label_wrap {
  font-weight: 400;
  margin: 0;
  position: relative;
  cursor: pointer;
  float: left;
  width: 100%;
  border-radius: 7px;
  transition: all 0.4s ease;
  box-shadow: 0 2px 4px 0 #cfd7df;
  padding: 10px;
  color: #333;
}
.credit_label_wrap_input:checked + .credit_label_wrap .credit_label_wrap_btm {
  display: block !important;
}
.credit_label_wrap_input:checked + .credit_label_wrap {
  box-shadow: 0 2px 1px 0 var(--main-theme-color);
}
.credit_label_wrap_input {
  display: none;
}
.card_img {
  float: right;
  height: 20px;
}
.credit_label_wrap_btm {
  float: left;
  width: 100%;
  margin: 10px 0 0;
}
.hide {
  display: none;
}
.credit-card-cvv {
  width: 90px;
  float: left;
  margin: 0px;
}
.paynow_card {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  background: var(--main-theme-color);
  padding: 6px 10px;
  border-radius: 2px;
  margin: 0px;
  transition: 0.5s all;
  float: right;
  cursor: pointer;
}
.not_your_cards {
  color: #777 !important;
  margin: 5px 10px 5px 0;
  display: flex;
}
.btn-default {
  background-color: #fff !important;
  border-color: #ccc !important;
}
.fls-add_new_wrap a {
  display: inline-block;
  text-decoration: none;
  color: #025dfa;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
}

.fls-Footer {
  width: 100%;
  text-align: center;
  margin-top: 110px;
}
.fls-power-by {
  text-align: center;
  width: 100%;
  color: #686868;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
}
.fls-footer-img{
  margin-bottom: 20px;
}
.clr_blue {
  color: #1654c3;
  padding: 0 5px;
  outline: 0;
  text-decoration: none;
}

.clr_blue:hover{
  color: #1654c3;
}
.fls-invalid{
  position: absolute;
  text-align: center;
  bottom:55%;
  width: 25%;
  background: #fff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
}
.fls-btn-card{
  text-align: center;
  width: 100%;
  background-color: var(--main-theme-color);
  color: #fff;
  border: 1px solid var(--main-theme-color);
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  transition: all 2s ease-in-out;
}
.fls-pay-card{
  height: 20px;
  margin-right: 5px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(323deg) brightness(102%) contrast(101%);
}
.fls-backtocard {
text-align: right;
width: 100%;
}
.fls-backtocard a{
  background-color: #fff !important;
  border-color: #ccc !important;
  text-decoration: none;color:#025dfa;cursor: pointer;font-size: 13px;font-weight: 400;}